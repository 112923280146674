import React from 'react';

import { IIconProps } from 'types';

const SearchSpotIcon: React.FC<IIconProps> = ({ ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="49" height="46" viewBox="0 0 49 46" fill="none" {...props}>
			<g clipPath="url(#clip0_850_8954)">
				<path
					d="M31.9465 16.6212L31.245 16.6665C30.0772 16.7399 29.8549 16.6557 27.8452 14.8943C28.4626 12.2198 28.1712 11.1448 27.3941 11.0585C26.5091 10.957 18.0754 16.8608 18.1683 17.517C18.2438 18.0523 20.8643 20.0749 21.4795 20.0749H21.8012L21.6889 20.2368C21.6263 20.3253 22.4531 20.9578 25.4708 22.8811C32.0545 28.1675 32.4689 28.6337 32.8186 30.1361C33.3129 32.2407 32.9373 32.9379 29.38 36.5493C24.5555 41.4406 24.6332 41.4406 19.9405 36.6658C15.4635 32.109 15.0879 30.4793 17.8509 27.6257C19.3792 26.0499 19.4375 26.0477 21.486 27.5156C23.133 28.6963 23.133 28.6963 22.4466 29.7152C21.5853 30.9931 21.6609 31.8738 22.7229 32.9401C24.1109 34.3346 26.1011 33.987 26.9904 32.1954C27.828 30.5052 27.4372 30.0972 19.8109 24.7159C11.9731 19.1856 11.8155 19.0042 12.5365 16.4355C12.9466 14.9806 13.1431 14.823 22.1746 8.68833C27.8431 4.8374 27.9985 4.7489 29.0864 4.7489C30.6579 4.7489 31.7739 5.44828 32.3739 6.81036C33.0021 8.23503 32.9632 15.4793 32.3243 16.1959L31.9465 16.6212Z"
					fill="url(#paint0_linear_850_8954)"
				/>
				<path
					d="M21.8008 20.075L27.8448 14.8943C29.8545 16.6558 30.0768 16.7399 31.2446 16.6666L31.9461 16.6212C31.6072 17.5753 30.338 18.8014 28.4989 20.3448L25.4704 22.8811C22.4527 20.9578 21.6259 20.3254 21.6885 20.2369L21.8008 20.075Z"
					fill="url(#paint1_linear_850_8954)"
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_850_8954"
					x1="12.076"
					y1="4.7489"
					x2="33.9486"
					y2="5.44742"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#DE7F54" />
					<stop offset="1" stopColor="#DF63C8" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_850_8954"
					x1="21.5939"
					y1="14.8943"
					x2="32.3765"
					y2="15.6515"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#DE7F54" />
					<stop offset="1" stopColor="#DF63C8" />
				</linearGradient>
				<clipPath id="clip0_850_8954">
					<rect width="49" height="45.3304" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default React.memo(SearchSpotIcon);
