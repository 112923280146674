import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'Enum';

import Screen from './Screen';

const Home = React.lazy(() => import('pages/Home'));
const Authentication = React.lazy(() => import('pages/Authentication'));

const Layout: React.FC = () => (
	<Suspense fallback={<></>}>
		<Router>
			<Screen>
				<Routes>
					<Route path={`${PATH.HOME}/:type?/:tab?`} element={<Home />} />
					<Route path={PATH.SIGN_IN} element={<Authentication />} />
					<Route path="*" element={<Navigate to={PATH.HOME} />} />
				</Routes>
			</Screen>
		</Router>
	</Suspense>
);

export default React.memo(Layout);
