import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddIcon from 'assets/AddIcon';
import ArrowLeftToLine from 'assets/ArrowLeftToLine';
import CrossIcon from 'assets/CrossIcon';
import SearchSpotIcon from 'assets/SearchSpotIcon';
import { PATH } from 'Enum';
import { useMediaQuery } from 'hooks/useMediaQuery';
import theme, { DESKTOP_SCREEN } from 'theme';

import Box from './Box';
import Button from './Button';
import Divider from './Divider';
import Typography from './Typography';

interface IProps {
	show?: boolean;
}

type SidebarState = 'expand' | 'collapse' | 'hidden' | 'mobile';

const useStyles = (sidebarState: SidebarState): Record<string, React.CSSProperties> => ({
	newChatButton: {
		border: '1px solid #3E5A8B',
		boxShadow: '0px 2px 1px 0px rgba(255, 255, 255, 0.45) inset',
		...(sidebarState === 'collapse' && {
			width: 34,
			height: 34,
			padding: 0,
		}),
		...(sidebarState === 'hidden' && {
			width: 0,
			height: 0,
			padding: 0,
		}),
	},
	sidebarContainer: {
		width: theme.sidebar[sidebarState] || 0,
		position: sidebarState === 'mobile' ? 'fixed' : 'relative',
		padding: '58px 16px',
		top: 0,
		left: 0,
		zIndex: 1000,
		...(sidebarState === 'hidden' && {
			padding: 0,
		}),
	},
});

const SideBar: React.FC<IProps> = ({ show }) => {
	const navigate = useNavigate();
	const isMobile = !useMediaQuery(DESKTOP_SCREEN);
	const [sidebarState, setSidebarState] = useState<SidebarState>(isMobile ? 'hidden' : 'expand');
	const STYLES = useStyles(sidebarState);

	useEffect(() => {
		setSidebarState(!show ? 'hidden' : isMobile && show ? 'mobile' : 'expand');
	}, [isMobile, show]);

	const toggleSidebar = () => {
		if (isMobile) {
			setSidebarState((prev) => (prev === 'hidden' ? 'mobile' : 'hidden'));
			return;
		}
		setSidebarState((prev) => (prev === 'expand' ? 'collapse' : 'expand'));
	};

	const routeToSignIn = () => navigate(PATH.SIGN_IN);

	// if (sidebarState === 'hidden') {
	// 	return (
	// 		<Box
	// 			position="fixed"
	// 			top={16}
	// 			left={16}
	// 			zIndex={1000}
	// 			display="flex"
	// 			alignItems="center"
	// 			justifyContent="center"
	// 			borderRadius="100%"
	// 			border="1px solid #CED8E9"
	// 			height={40}
	// 			width={40}
	// 			background="#F5F7FA"
	// 			onClick={toggleSidebar}
	// 			cursor="pointer">
	// 			<SearchSpotIcon width="24px" height="24px" />
	// 		</Box>
	// 	);
	// }

	const isExpanded = sidebarState === 'expand' || sidebarState === 'mobile';

	return (
		<Box
			id="sidebar_home"
			className="animated fadeInLeft"
			display="flex"
			alignItems="center"
			flexDirection="column"
			background="#F5F7FA"
			overflowY="auto"
			transition="width 0.3s ease-in-out"
			justifyContent="space-between"
			sx={STYLES.sidebarContainer}>
			<Box display="flex" flexDirection="column" gap={20} width="100%">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					marginBottom={isExpanded ? 27 : 8}>
					<Box display="flex" alignItems="center" gap={8}>
						<SearchSpotIcon width="30px" height="30px" />
						{isExpanded && (
							<Typography
								color="#4B4C55"
								fontSize={23}
								fontWeight={500}
								fontFamily="medium-font"
								className="animated fadeIn nowrap">
								SearchSpot AI
							</Typography>
						)}
					</Box>
					{isExpanded && (
						<Box onClick={toggleSidebar} cursor="pointer">
							{sidebarState === 'mobile' ? (
								<CrossIcon htmlColor="#000" />
							) : (
								<ArrowLeftToLine htmlColor="#000" />
							)}
						</Box>
					)}
				</Box>
				<Button variant="contained" startIcon={<AddIcon htmlColor="#fff" />} sx={STYLES.newChatButton}>
					{isExpanded && <span className="animated fadeIn">New Chat</span>}
				</Button>
				<Divider variant="dashed" color="#E9ECF5" />
				{sidebarState === 'collapse' && (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						borderRadius="100%"
						border="1px solid #CED8E9"
						height={32}
						width={32}
						background="#E9ECF5"
						onClick={toggleSidebar}
						cursor="pointer"
						transform="rotate(180deg)"
						transition="transform 0.3s ease-in-out"
						className="animated">
						<ArrowLeftToLine htmlColor="#000" />
					</Box>
				)}
			</Box>
			{isExpanded && (
				<Button
					variant="contained"
					width="-webkit-fill-available"
					sx={STYLES.newChatButton}
					onClick={routeToSignIn}>
					Sign In
				</Button>
			)}
		</Box>
	);
};

export default React.memo(SideBar);
