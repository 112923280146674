export enum STORAGE {
	TOKEN = 'TOKEN',
}

export enum PATH_VARIABLE {
	SEARCH = 'search',
	MAP = 'map',
	CHECKLIST = 'checklist',
	CHAT = 'chat',
}

export enum PATH {
	SIGN_IN = '/sign-in',
	HOME = '/explore',
}
