import 'styles/animate.css';
import 'styles/variable.css';

import React from 'react';

import Layout from 'layout';

const App: React.FC = () => {
	return <Layout />;
};

export default App;
