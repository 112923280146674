export const theme = {
	color: {
		primary: '#2D3F5F',
		error: '#C53434',
		success: '#57D883',
		warning: '#FEB872',
		secondary: '#F5F7FA',
	},
	sidebar: {
		expand: 240,
		collapse: 50,
		hidden: 0,
		mobile: '100vw',
	},
	home: {
		maxWidth: 654,
	},
};

export const DESKTOP_SCREEN = '(min-width : 557px)';

export default theme;
