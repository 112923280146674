import React, { useState } from 'react';

export interface IBoxProps extends React.CSSProperties {
	className?: string;
	children?: React.ReactNode;
	sx?: React.CSSProperties;
	hover?: React.CSSProperties;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	id?: string;
	ref?: React.RefObject<HTMLDivElement>;
	onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Box: React.FC<IBoxProps> = ({ children, sx, hover, onClick, id, ref, className, onMouseEnter, ...props }) => {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		if (onMouseEnter) onMouseEnter(event);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const mergedStyles = {
		...sx,
		...(isHovered ? hover : {}),
	};

	return (
		<div
			className={className}
			id={id}
			ref={ref}
			onClick={onClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ ...props, ...mergedStyles }}>
			{children}
		</div>
	);
};

export default React.memo(Box);
