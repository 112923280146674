import React from 'react';

import { IIconProps } from 'types';

const ArrowLeftToLine: React.FC<IIconProps> = ({ htmlColor, ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g>
				<path
					id="Vector"
					d="M2.17993 12.65V3.89999M8.42993 4.52499L4.67993 8.27499M4.67993 8.27499L8.42993 12.025M4.67993 8.27499H13.4299"
					stroke={htmlColor}
					strokeOpacity="0.5"
					strokeWidth="0.9375"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default React.memo(ArrowLeftToLine);
