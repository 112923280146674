import React from 'react';
import { useLocation } from 'react-router-dom';

import Box from 'components/Box';
import SideBar from 'components/SideBar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { DESKTOP_SCREEN } from 'theme';

import { getScreenConfig } from './config';

interface IProps {
	children?: JSX.Element;
}

const Screen: React.FC<IProps> = ({ children }) => {
	const location = useLocation();
	const desktopView = useMediaQuery(DESKTOP_SCREEN);
	const config = getScreenConfig(location.pathname, desktopView);

	return (
		<Box display="flex" height="100dvh" width="100dvw">
			<SideBar show={config.sideBarVisible} />
			{children}
		</Box>
	);
};

export default React.memo(Screen);
