import React from 'react';

import Box from './Box';

interface DividerProps {
	color?: string;
	variant?: 'solid' | 'dashed' | 'dotted';
	height?: number;
	children?: React.ReactNode;
}

const Divider: React.FC<DividerProps> = ({ color = '#E9ECF5', variant = 'solid', height = 1, children }) => {
	return (
		<Box display="flex" alignItems="center" gap={16} width="100%" flexWrap="nowrap">
			<Box width="100%" height="1px" borderBottom={`${height}px ${variant} ${color}`} />
			{children && (
				<>
					{children}
					<Box width="100%" height="1px" borderBottom={`${height}px ${variant} ${color}`} />
				</>
			)}
		</Box>
	);
};

export default React.memo(Divider);
