import React from 'react';

import { IIconProps } from 'types';

const AddIcon: React.FC<IIconProps> = ({ htmlColor, ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
			<path
				d="M3.125 7.55H11.875M7.5 3.175V11.925"
				stroke={htmlColor}
				strokeWidth="0.9375"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default React.memo(AddIcon);
