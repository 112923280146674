import { useSelector } from 'react-redux';

import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
	isSideBarVisible: true,
};

const AppSlice = createSlice({
	name: 'APP',
	initialState: INITIAL_STATE,
	reducers: {
		SHOW_SIDE_BAR: (state) => {
			if (state.isSideBarVisible) return;
			state.isSideBarVisible = true;
		},
		HIDE_SIDE_BAR: (state) => {
			if (!state.isSideBarVisible) return;
			state.isSideBarVisible = false;
		},
	},
});

export const { SHOW_SIDE_BAR, HIDE_SIDE_BAR } = AppSlice.actions;

export default AppSlice;

export interface IAppStates {
	[AppSlice.name]: ReturnType<typeof AppSlice.reducer>;
}

const sidebarVisibilitySelector = (state: IAppStates): boolean => state[AppSlice.name].isSideBarVisible;
export const useSidebarVisibility = (): boolean => useSelector(sidebarVisibilitySelector);
